<template>
  <v-row v-if="fetchingData">
    <v-col>
      <div class="text-center mt-5">
        <v-progress-circular indeterminate/>
      </div>
    </v-col>
  </v-row>
  <v-row v-else class="investor-profiles">
    <v-col cols="12" md="6">
      <v-select
        v-model="profile"
        :items="profiles"
        @change="getMatchedProperties()"
      >
        <template v-slot:item="{ item }">
          {{ item.name }}
        </template>

        <template v-slot:selection="{ item }">
          {{ item.name }}
        </template>
      </v-select>
    </v-col>
    <v-col
      cols="12"
      md="11"
    >
      <div class="d-flex justify-space-between flex-column flex-md-row">
        <div>
          <p>{{ $t('type') }}:</p>
          <p
            class="font-weight-bold mt-1"
            v-if="!isProfile"
          >
            {{ $t(`${this.profile.type}`) }}
          </p>
        </div>
        <div>
          <p>{{ $t('pum') }} (m<sup>2</sup>):</p>
          <p
            class="font-weight-bold mt-1"
            v-if="!isProfile"
          >
            {{ $t('from') }}
            {{ this.profile.parcel_profile_type.pum_min }}
            {{ $t('to') }}
            {{ this.profile.parcel_profile_type.pum_max }}

          </p>
        </div>
        <div>
          <p>{{ $t('puu') }} (m<sup>2</sup>):</p>
          <p
            class="font-weight-bold mt-1"
            v-if="!isProfile"
          >
            {{ $t('from') }}
            {{ this.profile.parcel_profile_type.puu_min }}
            {{ $t('to') }}
            {{ this.profile.parcel_profile_type.puu_max }}

          </p>
        </div>
        <div>
          <p>{{ $t('price') }} (PLN):</p>
          <p
            class="font-weight-bold mt-1"
            v-if="!isProfile"
          >
            {{ $t('from') }}
            {{ price(this.profile.parcel_profile_type.price_min) }}
            {{ $t('to') }}
            {{ price(this.profile.parcel_profile_type.price_max) }}
          </p>
        </div>
      </div>
      <router-link
        :to="{ name: 'editProfile', params: { uuid: this.$route.params.uuid, profileId: this.profile.id }}"
        v-if="!isProfile"
      >
        <v-btn class="btn-blue-rounded mt-5">
          {{ $t('editProfile') }}
        </v-btn>
      </router-link>
    </v-col>
    <v-col cols="12">
      <v-data-table
        v-if="matches.length > 0 && this.isContractAccepted"
        :headers="headers"
        :items="matches"
        class="mt-10"
        hide-default-footer
      >
        <template v-slot:item.actions="{ item }">
          <v-btn
            class="mx-1 btn-gradient-blue" small
            @click.stop="navigateToParcel(item)"
          >
            {{ $t('showOnMap')}}
          </v-btn>
        </template>

        <template v-slot:item.address="{ item }">
          <router-link
            :to="{ name: 'announcement', params: { propertyId: item.id }}"
            class="pointer"
          >
            {{ item.address }}
          </router-link>
        </template>
        <template v-slot:item.price="{ item }">
          {{ price(item.price) }}
        </template>
      </v-data-table>
      <div v-else-if="!this.isContractAccepted">
        {{ $t('investorProfilesInfo') }}
      </div>
      <div v-else>
        {{ $t('noMatches') }}
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { isEmpty } from 'lodash';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'InvestorProfiles',
  data: (vm) => ({
    fetchingData: false,
    isContractAccepted: null,
    profile: {},
    headers: [
      { text: vm.$t('address'), value: 'address' },
      { text: vm.$t('pum'), value: 'pum' },
      { text: vm.$t('price'), value: 'price' },
      // { text: vm.$t('price/pum'), value: 'price_pum' },
      // { text: vm.$t('observation'), value: 'observation' },
      // { text: vm.$t('status'), value: 'status' },
      { text: '', value: 'actions', sortable: false },
    ],
  }),
  props: {
    profiles: {
      type: Array,
    },
  },
  async created() {
    this.fetchingData = true;
    await this.currentUser();
    const investorContractId = {
      businessContractId: this.user.investor_contract.business_user_contract_id,
      naturalPersonContractId: this.user.investor_contract.natural_person_user_contract_id,
    };
    await this.getContractData(investorContractId);
    if (this.investorContractData) {
      this.isContractAccepted = this.investorContractData.status !== 'waiting_for_accept';
    } else {
      this.isContractAccepted = false;
    }
    this.fetchingData = false;
  },
  watch: {
    profiles: {
      handler() {
        [this.profile] = this.profiles;
        if (this.profile) {
          this.getInvestorMatches(this.profile.id);
        }
      },
    },
  },
  computed: {
    ...mapGetters('user', ['user', 'matches']),
    ...mapGetters('userContract', ['investorContractData']),
    isProfile() {
      return isEmpty(this.profile);
    },
  },
  methods: {
    ...mapActions('user', ['getInvestorMatches', 'currentUser']),
    ...mapActions('userContract', ['getContractData']),
    navigateToParcel(data) {
      this.$emit('goToParcel', data.lot_ids);
    },
    getMatchedProperties() {
      this.getInvestorMatches(this.profile.id);
    },
    price(amount) {
      return Number(amount).toLocaleString();
    },
  },
};
</script>

<style lang="scss">
</style>
