// eslint-disable-next-line import/prefer-default-export
export function findAllByKey(obj, keyToFind) {
  return Object.entries(obj)
    // eslint-disable-next-line no-nested-ternary
    .reduce((acc, [key, value]) => ((key === keyToFind)
      ? acc.concat(value)
      : (typeof value === 'object' && value)
        ? acc.concat(findAllByKey(value, keyToFind))
        : acc),
    []) || [];
}

export function getFormErrorMessages(errorData) {
  const errMessages = [];
  Object.values(errorData).flat().map((err) => errMessages.push(err.message));
  return errMessages;
}
