<template>
  <v-container fluid class="contract stepper">
    <v-row>
      <v-col v-if="fetching">
        <div class="text-center mt-5">
          <v-progress-circular indeterminate/>
        </div>
      </v-col>
      <v-col v-else>
        <v-alert
          type="error"
          v-model="propertiesError.isError"
          dismissible
          transition="fade-transition"
        >
          {{propertiesError.message}}
        </v-alert>
        <v-alert
          class="mt-5"
          type="error"
          v-model="error.isError"
          dismissible
          transition="fade-transition"
        >
          {{error.message}}
        </v-alert>
        <v-stepper
          v-if="owners.length === 0 || addingOwner && localStep < 5"
          v-model="localStep"
          vertical
          @change="stepperChanged"
        >
          <v-stepper-step
            :complete="currentStep > 1"
            editable
            step="1"
          >
            {{ $t("contractDetails") }}
          </v-stepper-step >
          <v-stepper-content step="1" class="pb-5">
            <OwnerData
              @ownerAdded="handleNewOwner"
              :owner="preloadOwner"
              :isActive="localStep === 1"
            />
          </v-stepper-content>

          <v-stepper-step
            :complete="currentStep > 2"
            :editable="currentStep > 1"
            step="2"
          >
            {{ $t("intermediationContract") }}
          </v-stepper-step>
          <v-stepper-content step="2">
            <IntermediationContact
              :isActive="localStep === 2"
              @completed="currentOwner.owner_type !== 'natural_person' ? nextStep(3) : nextStep(2)"
            />
          </v-stepper-content>

          <v-stepper-step
            :class="currentOwner.owner_type !== 'natural_person' && 'disabled-step'"
            step="3"
            :complete="currentStep > 3 || currentOwner.owner_type !== 'natural_person'"
            :editable="currentStep > 2 && currentOwner.owner_type === 'natural_person'"
          >
            {{ $t("resignationFromCancelContract") }}
          </v-stepper-step>
          <v-stepper-content step="3">
            <ContractCancelResignation
              :isActive="localStep === 3"
              @completed="nextStep(3)"
            />
          </v-stepper-content>

          <v-stepper-step
            step="4"
            :complete="currentStep > 4"
            :editable="currentStep > 3"
          >
            {{ $t("shipmentDetails") }}
          </v-stepper-step>
          <v-stepper-content step="4">
            <ShippingAddress
              :currentOwner="this.currentOwner"
              @completedDeliveryForm="completedDeliveryForm"
            />
          </v-stepper-content>

        </v-stepper>
        <v-container class="d-flex flex-column align-center justify-center vh-100" v-else-if="localStep === 5">
          <v-row class="justify-center align-content-end">
            <v-col cols="8" class="text-center">
              {{ $t("offerSendInfo") }}
            </v-col>
          </v-row>
          <v-row class="justify-center">
            <v-col>
              <v-btn
                @click="nextStep(5)"
                class="w-100 btn-blue-rounded"
              >
                {{ $t("next") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
        <div v-else-if="localStep === 1 || localStep > 5">
          <OwnersList
            :owners="owners"
            @editOwner="handleEditOwner"
            @deleteOwner="handleDeleteOwner"
          />
          <v-row>
            <v-col class="text-center d-flex flex-column flex-sm-row justify-center">
              <v-btn
                class="elevation-0 mr-sm-5 text text-capitalize button-text-color"
                color="primary"
                outlined
                @click="addAnotherOwner"
              >
                {{ $t('addAnotherOwner') }}
              </v-btn>
              <v-btn
                class="elevation-0 mt-5 mt-sm-0 text-capitalize"
                color="primary"
                @click="showProperties"
              >
                {{ $t('next') }}
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </v-col>
      <v-col v-if="localStep === 2 || localStep === 3 && currentOwner.owner_type !== 'company'">
        <v-container class="pa-0">
          <v-card
            elevation="4"
            class="mb-1"
          >
            <v-card-title v-if="localStep === 2" class="justify-center">
              {{ $t('contractPreview') }}
            </v-card-title>
            <v-card-title v-if="localStep === 3" class="justify-center">
              {{ $t('resignationFromCancelContractPreview') }}
            </v-card-title>
          </v-card>
          <v-card
            elevation="4"
            class="d-flex justify-center pdf-preview"
          >
            <vue-pdf-embed
              :source="localStep === 2 ? this.pdfContract : this.pdfContractCancelResignation"
            />
          </v-card>
          <v-card
            elevation="4"
            class="mt-1"
          >
            <v-card-title class="justify-end">
              <a
                :href="localStep === 2 ? this.pdfContract : this.pdfContractCancelResignation"
                :download="localStep === 2 ? `contract-${Date.now()}.pdf` : `resignation-${Date.now()}.pdf`"
              >
                <v-btn class="btn-blue-rounded">
                  {{ $t('downloadPdf') }}
                  <v-icon class="ms-3">
                    mdi-file-pdf-box
                  </v-icon>
                </v-btn>
              </a>
            </v-card-title>
          </v-card>
        </v-container>
      </v-col>
      <v-col v-else class="hidden-sm-and-down">
        <div class="agreement-image"/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import OwnerData from '@/components/contractForm/OwnerData.vue';
import OwnersList from '@/components/contractForm/OwnersList.vue';
import IntermediationContact from '@/components/contractForm/IntermediationContact.vue';
import ContractCancelResignation from '@/components/contractForm/ContractCancelResignation.vue';
import ShippingAddress from '@/components/contractForm/ShippingAddress.vue';
import { mapActions, mapGetters } from 'vuex';
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed';

export default {
  name: 'Contract',
  components: {
    OwnerData,
    OwnersList,
    IntermediationContact,
    ContractCancelResignation,
    ShippingAddress,
    VuePdfEmbed,
  },
  async created() {
    const { propertyId } = this.$route.params;
    this.propertyId = propertyId;
    this.fetching = true;
    await this.fetchContracts(propertyId);
    this.fetching = false;
    this.localStep = 1;
    this.setStep(1);
  },
  data: () => ({
    localStep: 1,
    currentOwner: {},
    propertyId: null,
    addingOwner: false,
    preloadOwner: null,
    fetching: false,
    pdfContract: null,
    pdfContractCancelResignation: null,
  }),
  computed: {
    ...mapGetters('contract',
      [
        'naturalPersonOwners',
        'businessOwners',
        'intermediationContract',
        'contractCancelResignation',
        'currentStep',
        'error',
      ]),
    ...mapGetters('properties', ['propertiesError']),
    owners() {
      const result = [];
      if (this.naturalPersonOwners) {
        result.push(...this.naturalPersonOwners.map((owner) => ({
          ...owner,
          owner_type: 'natural_person',
        })));
      }

      if (this.businessOwners) {
        result.push(...this.businessOwners.map((owner) => ({
          ...owner,
          owner_type: 'company',
        })));
      }

      return result;
    },
  },
  methods: {
    ...mapActions('contract', [
      'createOrUpdateNaturalPersonOwner',
      'createOrUpdateBusinessOwner',
      'fetchContracts',
      'deleteNaturalPersonOwner',
      'deleteBusinessOwner',
      'setStep',
      'getIntermediationContract',
      'getContractCancelResignation',
    ]),
    ...mapActions('companyPartner', ['createOrUpdateCompanyPartner']),
    ...mapActions('properties', ['changePropertyStatus']),
    async handleNewOwner({ owner, partners }) {
      if (owner.owner_type === 'natural_person') {
        await this.handleNaturalPersonAdded(owner);
      } else {
        await this.handleCompanyAdded(owner, partners);
      }
      if (!this.error.isError) {
        await this.showIntermediationContractPdf();
        await this.showContractCancelResignationPdf();
      }
      this.addingOwner = true;
      this.preloadOwner = null;
    },
    addAnotherOwner() {
      this.nextStep(0);
      this.addingOwner = true;
    },
    handleEditOwner(owner) {
      this.preloadOwner = owner;
      this.currentOwner = owner;
      this.nextStep(0);
      this.addingOwner = true;
    },
    handleDeleteOwner(owner) {
      if (owner.owner_type === 'natural_person') {
        this.deleteNaturalPersonOwner(owner);
      } else {
        this.deleteBusinessOwner(owner);
      }
      this.currentOwner = {};
    },
    showProperties() {
      this.$router.push({ name: 'myParcels' });
    },
    stepperChanged(val) {
      this.localStep = parseInt(val, 10);
    },
    nextStep(fromStep) {
      this.setStep(fromStep + 1);
      this.localStep = fromStep + 1;
    },
    async completedDeliveryForm(data) {
      if (data) {
        this.fetching = true;
        if (this.currentOwner.owner_type === 'natural_person') {
          await this.createOrUpdateNaturalPersonOwner({
            id: this.currentOwner.id,
            delivery_address: data.id,
          });
        } else {
          await this.createOrUpdateBusinessOwner({
            id: this.currentOwner.id,
            delivery_address: data.id,
          });
        }
        await this.changePropertyStatus(this.propertyId);
        this.fetching = false;
        this.nextStep(4);
      }
    },
    async showIntermediationContractPdf() {
      await this.getIntermediationContract(this.propertyId);
      this.pdfContract = this.intermediationContract;
    },
    async showContractCancelResignationPdf() {
      await this.getContractCancelResignation(this.propertyId);
      this.pdfContractCancelResignation = this.contractCancelResignation;
    },
    async handleNaturalPersonAdded(owner) {
      const currentOwner = await this.createOrUpdateNaturalPersonOwner({
        ...owner,
        property: this.propertyId,
      });
      if (currentOwner) {
        this.currentOwner = {
          ...currentOwner,
          owner_type: 'natural_person',
        };
      }
      if (!this.error.isError) {
        await this.nextStep(1);
      }
    },
    async handleCompanyAdded(owner, partners) {
      const currentOwner = await this.createOrUpdateBusinessOwner({
        ...owner,
        property: this.propertyId,
      });
      if (currentOwner) {
        this.currentOwner = {
          ...currentOwner,
          owner_type: 'company',
        };
      }
      if (partners.length && currentOwner) {
        await Promise.all(partners.map((partner) => {
          this.createOrUpdateCompanyPartner({
            ...partner,
            property_contract: currentOwner.id,
          });
          return null;
        }));
      }
      if (!this.error.isError) {
        await this.nextStep(1);
      }
    },
  },
};
</script>

<style scoped lang="scss">
  .contract {
    height: 100vh;
  }

  .agreement-image {
    height: 100%;
    width: 50%;
    position: absolute;
    right: 0;
    background-image: url("../assets/images/background.jpg");
    background-position: center;
    background-size: cover;
  }

  .pdf-preview {
    .vue-pdf-embed {
      width: 90%;
      max-height: 80vh;
      overflow-x: hidden;
      overflow-y: scroll;
    }
    background-color: $f-grey-light;
  }

  .button-text-color {
    ::v-deep .v-btn__content {
      color: #1974E4 !important;
    }
  }

  .disabled-step {
    ::v-deep .v-stepper__label {
      color: rgba(0, 0, 0, 0.38) !important;
    }
    ::v-deep .v-stepper__step__step {
      border-color: rgba(0, 0, 0, 0.38) !important;
      i {
        color: rgba(0, 0, 0, 0.38) !important;
      }
    }
  }
</style>
