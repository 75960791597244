import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import { getFormErrorMessages } from '@/helpers/functions';

Vue.use(Vuex);
/* eslint-disable dot-notation */

export default {
  namespaced: true,
  state: {
    investorContractData: null,
    currentStep: 1,
    intermediationInvestorContract: null,
    error: {
      isError: false,
      message: '',
    },
  },
  mutations: {
    SET_STEP: (state, step) => {
      state.currentStep = step;
    },
    SET_INVESTOR_CONTRACT_DATA: (state, payload) => {
      state.investorContractData = payload;
    },
    RESET_CONTRACT_DATA: (state) => {
      state.investorContractData = null;
    },
    SET_INTERMEDIATION_CONTRACT: (state, data) => {
      state.intermediationInvestorContract = data;
    },
    SET_ERROR: (state, data) => {
      state.error = data;
    },
  },
  getters: {
    currentStep: (state) => state.currentStep,
    investorContractData: (state) => state.investorContractData,
    intermediationInvestorContract: (state) => state.intermediationInvestorContract,
    error: (state) => state.error,
  },
  actions: {
    setStep: ({ commit }, step) => {
      commit('SET_STEP', step);
    },
    getContractData: async ({ commit }, contractId) => {
      try {
        axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
        if (contractId.businessContractId) {
          const response = await axios.get(
            `${process.env.VUE_APP_HOST_URL}/user_business_contract/${contractId.businessContractId}/`,
          );
          if (response && response.data) {
            commit('SET_INVESTOR_CONTRACT_DATA', { ...response.data, owner_type: 'company' });
          }
        } else if (contractId.naturalPersonContractId) {
          const response = await axios.get(
            `${process.env.VUE_APP_HOST_URL}/user_natural_person_contract/${contractId.naturalPersonContractId}/`,
          );
          if (response && response.data) {
            commit('SET_INVESTOR_CONTRACT_DATA', { ...response.data, owner_type: 'natural_person' });
          }
        }
      } catch (error) {
        commit('SET_ERROR', { isError: true, message: `Data fetching error: ${error.message}` });
      }
    },
    createOrUpdateNaturalPersonContract: async ({ commit }, payload) => {
      try {
        if (payload.id) {
          axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
          const response = await axios.patch(
            `${process.env.VUE_APP_HOST_URL}/user_natural_person_contract/${payload.id}/`,
            payload,
          );
          if (response && response.data) {
            commit('SET_INVESTOR_CONTRACT_DATA', { ...response.data, owner_type: 'natural_person' });
            commit('SET_ERROR', { isError: false, message: '' });
          }
        } else {
          axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
          const response = await axios.post(
            `${process.env.VUE_APP_HOST_URL}/user_natural_person_contract/`,
            payload,
          );
          if (response && response.data) {
            commit('SET_INVESTOR_CONTRACT_DATA', { ...response.data, owner_type: 'natural_person' });
            commit('SET_ERROR', { isError: false, message: '' });
          }
        }
      } catch (error) {
        commit('SET_ERROR', { isError: true, message: `Error: ${getFormErrorMessages(error.response.data)}` });
      }
    },
    createOrUpdateBusinessContract: async ({ commit }, payload) => {
      try {
        if (payload.id) {
          axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
          const response = await axios.patch(
            `${process.env.VUE_APP_HOST_URL}/user_business_contract/${payload.id}/`,
            payload,
          );
          if (response && response.data) {
            commit('SET_INVESTOR_CONTRACT_DATA', { ...response.data, owner_type: 'company' });
            commit('SET_ERROR', { isError: false, message: '' });
          }
        } else {
          axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
          const response = await axios.post(
            `${process.env.VUE_APP_HOST_URL}/user_business_contract/`,
            payload,
          );
          if (response && response.data) {
            commit('SET_INVESTOR_CONTRACT_DATA', { ...response.data, owner_type: 'company' });
            commit('SET_ERROR', { isError: false, message: '' });
          }
        }
      } catch (error) {
        commit('SET_ERROR', { isError: true, message: `Error: ${getFormErrorMessages(error.response.data)}` });
      }
    },
    deleteBusinessContract: async ({ commit }, contractId) => {
      try {
        await axios.delete(`${process.env.VUE_APP_HOST_URL}/user_business_contract/${contractId}/`);
      } catch (error) {
        commit('SET_ERROR', { isError: true, message: `Error: ${error.message}` });
      }
    },
    deleteNaturalPersonContract: async ({ commit }, contractId) => {
      try {
        await axios.delete(`${process.env.VUE_APP_HOST_URL}/user_natural_person_contract/${contractId}/`);
      } catch (error) {
        commit('SET_ERROR', { isError: true, message: `Error: ${error.message}` });
      }
    },
    resetContractData: ({ commit }) => {
      commit('RESET_CONTRACT_DATA');
    },
    getIntermediationInvestorContract: async ({ commit }) => {
      try {
        axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem('token')}`;
        const response = await axios.get(`${process.env.VUE_APP_HOST_URL}/user_contract_pdf/`, {
          responseType: 'blob',
        });
        if (response && response.data) {
          const blob = new Blob([response.data]);
          const pdfUrl = URL.createObjectURL(blob);
          commit('SET_INTERMEDIATION_CONTRACT', pdfUrl);
        }
      } catch (error) {
        commit('SET_ERROR', { isError: true, message: `Data fetching error: ${error.message}` });
      }
    },
  },
};
